import {Navigate, useLocation} from "react-router-dom";
import {EuiLoadingSpinner} from '@elastic/eui/es'
import {AuthContext} from "../context/AuthContext";
import {useContext} from "react";
import {ROUTE_PATHS} from "../constants";

function RequireAuth({ children }) {
    let {isLoggedIn, isCheckingLoginState} = useContext(AuthContext);
    let location = useLocation();

    if(isCheckingLoginState){
        console.log('checking login state')
        return <EuiLoadingSpinner />
    }

    if(location.pathname === ROUTE_PATHS.LOGIN && isLoggedIn){
        return <Navigate to="/" />;
    }

    if(location.pathname === ROUTE_PATHS.LOGIN && !isLoggedIn){
        return children
    }


    if (!isLoggedIn) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to={ROUTE_PATHS.LOGIN} state={{ from: location }} replace />;
    }

    return children;
}

export default RequireAuth