import {
    EuiButton,
    EuiTextArea,
    EuiFilePicker,
    EuiForm,
    EuiFormRow,
    EuiSelect,
    EuiSpacer,
    EuiSwitch,
    EuiDatePicker,
} from '@elastic/eui/es';
import moment from 'moment';
import React, {useContext, useState} from 'react';
import httpClient from "../utils/httpClient";

import {baseAPIURL} from "../constants";
import {ToastContext} from "../context/ToastContext";

export default () => {
    const {addSuccessToast, addErrorToast} = useContext(ToastContext)
    const [isLoading, setIsLoading] = useState(false)
    const [isSwitchChecked, setIsSwitchChecked] = useState(false);

    const onSwitchChange = () => {
        setIsSwitchChecked(!isSwitchChecked);
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        setIsLoading(true);
        const formData = new FormData(e.target)
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        httpClient.post(`${baseAPIURL}/queue/add`, formData, config).then(() => addSuccessToast({
            title: 'Done!',
            message: 'Messages queued for sending'
        })).catch(() => addErrorToast({
            title: 'Uh Oh!',
            message: 'There was a problem sending this message'
        })).finally(() => setIsLoading(false))
    }

    return (
        <EuiForm onSubmit={handleSubmit} component="form">
            <EuiFormRow fullWidth label="Whatsapp Message Text" helpText="Enter text for whatsapp message">
                <EuiTextArea
                    name={'message_text'}
                    placeholder="Ik Mauka AAP Nu"
                    // value={value}
                    // onChange={(e) => onChange(e)}
                />
            </EuiFormRow>

            <EuiFormRow
                label="Select Chat Type"
            >
                <EuiSelect
                    name={'type_of_chat'}
                    options={[
                        {value: 'group', text: 'Group Chat'},
                        {value: 'individual', text: 'Individual Chat'},
                    ]}
                />
            </EuiFormRow>

            <EuiFormRow label="Pick Media File">
                <EuiFilePicker name={'media'}/>
            </EuiFormRow>

            <EuiSpacer/>

            <EuiFormRow
                label="Send Immediately"
                hasChildLabel={false}
            >
                <EuiSwitch
                    name="switch"
                    label="Should send immediately?"
                    checked={isSwitchChecked}
                    onChange={onSwitchChange}
                />
            </EuiFormRow>

            <EuiSpacer/>

            {
                !isSwitchChecked && <EuiFormRow label="Scheduled Send Time">
                    <EuiDatePicker
                        name={'date'}
                        minDate={moment()}
                        showTimeSelect
                    />
                </EuiFormRow>
            }

            <EuiSpacer/>

            <EuiButton isLoading={isLoading} type={'submit'} fullWidth fill>
                Save form
            </EuiButton>
        </EuiForm>
    );
};
