import React, {useEffect, useState} from 'react';
import {EuiInMemoryTable} from "@elastic/eui/es/components/basic_table";
import {EuiImage} from "@elastic/eui/es/components/image";

function getConvertedDate(startTimeISOString) {
	let startTime = new Date(startTimeISOString);
	return new Date(startTime.getTime() + (startTime.getTimezoneOffset() * 60000))
}


function getTableColumns() {
	return [
		{
			field: 'management_title',
			name: 'Management Title',
			sortable: true,
		},
		{
			field: 'task_id',
			name: 'Task Id',
			sortable: true,
		},
		{
			field: 'type_of_chat',
			name: 'Type Of Chat',
			sortable: true,
		},
		{
			field: 'message_text',
			name: 'Message Text',
			sortable: true,
		},
		{
			field: 'task_creation_time',
			name: 'Task Creation Time',
			sortable: true,
			render: (time) => (
				`${getConvertedDate(time)}`
			),
		},
		{
			field: 'media_url',
			name: 'Media Url',
			sortable: true,
			render: (imagePath) => (
				<EuiImage src={imagePath} alt="message image"/>
			),
		},
	];
}

function ListTasks() {
	const [error, setError] = useState(null);
	const [isLoaded, setIsLoaded] = useState(false);
	const [tableData, setTableData] = useState([]);
	const columns = getTableColumns();

	useEffect(() => {
		fetch("https://elf22-api-contract.amanworks.workers.dev/queue")
			.then(res => res.json())
			.then(
				(result) => {
					setIsLoaded(true);
					setTableData(result);
				},
				(error) => {
					setIsLoaded(true);
					setError(error);
				}
			)
	}, [])

	if (error) {
		return (<div>Error: {error.message}</div>);
	} else if (!isLoaded) {
		return (<div>Loading...</div>);
	} else {
		return (
			<EuiInMemoryTable
				tableCaption="Queued Messages"
				items={tableData}
				sorting={true}
				search={true}
				pagination={true}
				columns={columns}
			/>
		);
	}
}

export default ListTasks;
