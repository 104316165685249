import PropTypes from "prop-types";
import {EuiBadge, EuiInMemoryTable} from "@elastic/eui/es";
import httpClient from "../utils/httpClient";
import {baseAPIURL} from "../constants";
import {useContext} from "react";
import {ToastContext} from "../context/ToastContext";

const greenColour = '#70d73b'

const VolunteerProfiles = (props) => {
    const {addErrorToast, addSuccessToast} = useContext(ToastContext)
    const {
        chats,
    } = props
    const columns = [
        {
            field: 'chat.title',
            name: 'Group Name',
            truncateText: false,
            sortable: true,
            header: true,
        },
        {
            field: 'messaging_permitted',
            name: 'Chat Enabled Status',
            truncateText: false,
            sortable: true,
            render: (enabled) => {
                const color = enabled ? greenColour : 'warning';
                const label = enabled ? 'Enabled' : 'Disabled';
                return (
                    <span>
                        <EuiBadge color={color}>{label}</EuiBadge>
                    </span>
                )
            },
            mobileOptions: {
                header: true,
                truncateText: false,
                enlarge: true,
                fullWidth: true,
            },
        },
        {
            name: 'Actions',
            actions: [
                {
                    isPrimary: true,
                    name: `Change status`,
                    description: `Change status`,
                    type: 'icon',
                    icon: 'pencil',
                    onClick: ({messaging_permitted, id, chat:{ title}}) => {
                        (async () => {
                            try {
                                const {data} = await httpClient.patch(`${baseAPIURL}/profile_chat_status_change/${id}`, {
                                    messaging_permitted: !messaging_permitted,
                                })
                                addSuccessToast({
                                    title: 'Messaging preference updated',
                                    message: `Preference updated for ${title}. It is now ${data.messaging_permitted ? 'enabled': 'disabled'}.`
                                })
                            } catch (e) {
                                addErrorToast({title: 'Error updating status', message: 'We could not update the status'})
                            }
                        })();
                    },
                    color: 'primary'
                }
            ],
        },
    ];
    const sorting = {
        sort: {
            field: 'chat.title',
            direction: 'desc',
        },
    };
    return (
        <EuiInMemoryTable
            items={chats}
            columns={columns}
            hasActions={true}
            sorting={sorting}
        />
    );
};

VolunteerProfiles.propTypes = {
    chats: PropTypes.array.isRequired,
}

export default VolunteerProfiles