import React, {useContext, useEffect, useState} from 'react';
import httpClient from "../utils/httpClient";
import axios from "axios";
import {
    EuiButton,
    EuiDescriptionList,
    EuiFieldNumber,
    EuiFieldText,
    EuiFlexGroup,
    EuiFlexItem,
    EuiForm,
    EuiFormRow,
    EuiImage,
    EuiLoadingSpinner,
} from '@elastic/eui/es';
import {ToastContext} from "../context/ToastContext";
import {baseAPIURL, getImageURL} from "../constants";
import DetailsConfirmModal from "../components/DetailsConfirmModal";


const requirements = [{
    title: 'Multi-device beta is on', description: 'I have turned on whatsapp multi-device beta',
}, {
    title: 'Auto Media Download Off', description: 'Auto media download is turned off',
}, {
    title: 'Whatsapp account is old', description: 'Whatsapp account should be old',
}];

export default () => {
    const {addToast} = useContext(ToastContext)
    const [imageContent, setImageContent] = useState('')
    const [previousInterval, setPreviousInterval] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [profileName, setProfileName] = useState('')

    const [shouldShowConfirmationModal, setShouldShowConfirmationModal] = useState(false)

    const [isQrCodeInProgress, setQrCodeInProgress] = useState(false)
    const [isImageLoaded, setImageLoaded] = useState(false)
    const [imageURL, setImageURL] = useState('')

    const fetchImage = (imageURL) => axios.get(imageURL)

    const clearPreviousInterval = () => {
        if (previousInterval) {
            clearInterval(previousInterval)
        }
    }

    const showConfirmationModal = () => {
        setShouldShowConfirmationModal(true)
    }

    useEffect(() => {
        if (imageURL) {
            clearPreviousInterval()
            setTimeout(() => {
                addToast({
                    title: 'Please Retry',
                    color: 'warning',
                    text: <p>Could not complete login, please retry logging in</p>,
                })
                setImageURL('')
            }, 400000)
        }
    }, [imageURL])

    useEffect(() => {
        if (imageURL) {
            const intervalID = setInterval(() => {
                console.log('starting to load image')
                return fetchImage(imageURL).then(({data}) => {
                    if (!imageContent || imageContent !== data) {
                        setImageContent(data)
                    }
                    console.log('loading image')
                }).catch(() => {
                    console.log('could not load image, trying again')
                })
            }, 2000)
            setPreviousInterval(intervalID)
        }
    }, [imageURL])

    useEffect(() => {
        if (imageContent) {
            console.log('imageContent updated', imageContent)
            setImageLoaded(false)
            setTimeout(() => setImageLoaded(true), 1000)
        }
    }, [imageContent])

    useEffect(() => {
        if (!isQrCodeInProgress) {
            clearPreviousInterval()
        }
    }, [isQrCodeInProgress])

    const handleSubmit = () => {
        setShouldShowConfirmationModal(false)
        clearPreviousInterval();
        (async () => {
            setQrCodeInProgress(true)
            const timestamp = new Date().valueOf();
            setImageURL(getImageURL({timestamp, profileName, phoneNumber}))
            try {
                const response = await httpClient.post(`${baseAPIURL}/volunteer_login`, {
                    'volunteer_name': profileName, 'volunteer_phone_number': phoneNumber, timestamp,
                })
                if (response.data.logged_in) {
                    addToast({
                        title: 'Login Complete!',
                        color: 'success',
                        text: <p>Volunteer Login Complete! Thanks for your patience!</p>,
                    })
                    setPhoneNumber('')
                    setProfileName('')
                } else {
                    throw new Error('Failure logging in')
                }
            } catch (error) {
                addToast({
                    title: 'Login timed out',
                    color: 'danger',
                    iconType: 'help',
                    text: <p>There was an error trying to complete the login. Please try again.</p>,
                })
            } finally {
                // reset to initial state
                setQrCodeInProgress(false)
                setImageLoaded(false)
                setImageURL('')
                setImageContent('')
                clearPreviousInterval();
            }
            setQrCodeInProgress(false)
        })();
    }
    const profileNameIsValid = profileName.length >= 3
    const phoneNumberIsValid = phoneNumber.length === 10
    const formValid = profileNameIsValid && phoneNumberIsValid

    if (isQrCodeInProgress && !isImageLoaded) {
        return (<EuiLoadingSpinner size="xl"/>)
    }

    if (!isImageLoaded && imageContent) {
        return (<EuiLoadingSpinner size="xl"/>)
    }

    return (<>
        <EuiForm component="form">
            <EuiFormRow label="Profile Name" helpText="Volunteer profile name">
                <EuiFieldText
                    isInvalid={!(profileNameIsValid)}
                    value={profileName} onChange={({target: {value}}) => {
                    setProfileName(value)
                }} placeholder={'Sardar Bhagwant Mann'}/>
            </EuiFormRow>

            <EuiFormRow label="Volunteer Whatsapp Number">
                <EuiFieldNumber
                    isInvalid={!(phoneNumberIsValid)}
                    value={phoneNumber}
                    onChange={({target: {value}}) => {
                        setPhoneNumber(value)
                    }}
                    placeholder="9876541234"
                />
            </EuiFormRow>

            <EuiButton isDisabled={!formValid} onClick={showConfirmationModal} fullWidth fill>
                Generate QR Code
            </EuiButton>
            {!isQrCodeInProgress && shouldShowConfirmationModal &&
                <DetailsConfirmModal cancelText={'No, all requirements are not satisfied'}
                                     confirmText={'Yes, all requirements are satisfied'} onConfirm={handleSubmit}
                                     onCancel={() => setShouldShowConfirmationModal(false)}
                >
                    <EuiFlexGroup>
                        <EuiFlexItem>
                            <EuiDescriptionList listItems={requirements}/>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </DetailsConfirmModal>}
        </EuiForm>
        {isQrCodeInProgress && isImageLoaded && imageURL && imageContent && <EuiImage
            size="l"
            hasShadow
            allowFullScreen
            caption="Whatsapp QR Code"
            fullScreenIconColor="dark"
            src={`${imageURL}?ts=${new Date()}`}
        />}
    </>);
};