import {EuiButton, EuiFieldText, EuiForm, EuiFormRow} from "@elastic/eui/es";
import {useNavigate} from 'react-router-dom'
import {useContext, useState} from "react";
import {ToastContext} from "../context/ToastContext";
import {AuthContext} from "../context/AuthContext";

function Login() {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false)
    const {addSuccessToast, addErrorToast} = useContext(ToastContext)
    const {login} = useContext(AuthContext)
    const handleSubmit = (event) => {
        event.preventDefault();
        setIsLoading(true);
        (async () => {
            const formValues = new FormData(event.target)
            console.log(formValues)
            try {
                const loginAction = await login(formValues)
                addSuccessToast({message: 'Login Complete! You will be redirected to login page now!'});
                navigate('/')
            } catch ({response: {data}}) {
                addErrorToast({message: data?.non_field_errors?.[0] ?? 'Error logging in'})
            } finally {
                setIsLoading(false)
            }
        })();
    }
    return (
        <EuiForm onSubmit={handleSubmit} component={'form'}>
            <EuiFormRow label="Username" helpText="Your assigned username">
                <EuiFieldText name={'username'} fullWidth/>
            </EuiFormRow>

            <EuiFormRow helpText={'Your assigned password'} label="Password">
                <EuiFieldText name={'password'} type={'password'}/>
            </EuiFormRow>

            <EuiFormRow hasEmptyLabelSpace>
                <EuiButton isLoading={isLoading} type={'submit'} fullWidth fill>Login</EuiButton>
            </EuiFormRow>
        </EuiForm>
    )
}

export default Login