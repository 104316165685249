import useSWR from 'swr'
import {EuiInMemoryTable,
    EuiErrorBoundary,
    EuiPanel,
    RIGHT_ALIGNMENT,
    EuiButtonIcon,
    EuiBadge,
} from "@elastic/eui/es";
import httpClient from "../utils/httpClient";
import {useContext, useEffect, useState} from "react";
import {baseAPIURL} from "../constants";
import {ToastContext} from "../context/ToastContext";
import VolunteerProfiles from "../components/VolunteerProfiles";

const fetcher = url => httpClient.get(url).then(res => res.data)

const greenColour = '#70d73b'

function ProfileList() {
    const [itemIdToExpandedRowMap, setItemIdToExpandedRowMap] = useState({});
    const {addErrorToast, addSuccessToast} = useContext(ToastContext)

    const { data, error } = useSWR(`${baseAPIURL}/profiles/`, fetcher, { refreshInterval: 5000 })

    useEffect(() => {
        if(error){
            addErrorToast({title: 'Failure Loading Profiles', message: 'We could not load whatsapp profiles, please try again later'})
        }
    }, [error])

    const sorting = {
        sort: {
            field: 'dateOfBirth',
            direction: 'desc',
        },
    };


    const columns = [
        {
            field: 'volunteer_name',
            name: 'Volunteer Name',
            sortable: true,
            truncateText: true,
        },
        {
            field: 'volunteer_phone_number',
            name: 'Volunteer Phone Number',
            truncateText: true,
        },
        {
            field: 'created_at',
            name: 'Profile addition date',
            dataType: 'date',
            sortable: true,
        },
        {
            field: 'expired',
            name: 'Chat Expiry Status',
            truncateText: false,
            sortable: true,
            render: (expired) => {
                const color = expired ? 'danger' : greenColour;
                const label = expired ? 'Expired' : 'Active';
                return (
                    <span>
                        <EuiBadge color={color}>{label}</EuiBadge>
                    </span>
                )
            }
        },
        {
            field: 'last_populated',
            name: 'Last populated date',
            dataType: 'date',
            sortable: true,
        },
        {
            name: 'Actions',
            actions: [
                {
                    isPrimary: true,
                    name: `Populate Profile Chats`,
                    description: `Populate Profile Chats`,
                    type: 'icon',
                    icon: 'refresh',
                    onClick: ({id}) => {
                        (async () => {
                            try {
                                const {data} = await httpClient.post(`${baseAPIURL}/profiles/${id}/populate_chats/`)
                                addSuccessToast({
                                    title: 'Messaging preference updated',
                                    message: `Profile chats updating`
                                })
                            } catch (e) {
                                addErrorToast({title: 'Error populating profile', message: 'We could not populate profile chats'})
                            }
                        })();
                    },
                    color: 'primary'
                }
            ],
        },
        {
            name: 'Show Details',
            truncateText: false,
            align: RIGHT_ALIGNMENT,
            width: '120px',
            isExpander: true,
            render: (item) => (
                <EuiButtonIcon
                    onClick={() => toggleDetails(item)}
                    aria-label={itemIdToExpandedRowMap[item.id] ? 'Collapse' : 'Expand'}
                    iconType={itemIdToExpandedRowMap[item.id] ? 'arrowUp' : 'arrowDown'}
                />
            ),
        },
    ];

    const toggleDetails = (item) => {
        const itemIdToExpandedRowMapValues = {...itemIdToExpandedRowMap};
        if (itemIdToExpandedRowMapValues[item.id]) {
            delete itemIdToExpandedRowMapValues[item.id];
        } else {
            itemIdToExpandedRowMapValues[item.id] = (
                <EuiErrorBoundary>
                    <EuiPanel paddingSize={'s'} color={'primary'} hasShadow={true} hasBorder={true}>
                        <VolunteerProfiles chats={item.profile_chats }/>
                    </EuiPanel>
                </EuiErrorBoundary>
            );
        }
        setItemIdToExpandedRowMap(itemIdToExpandedRowMapValues);
    };

    useEffect(() => {
        console.log(itemIdToExpandedRowMap)
    }, [itemIdToExpandedRowMap])

    return (
        <EuiInMemoryTable
            itemIdToExpandedRowMap={itemIdToExpandedRowMap}
            tableCaption="Whatsapp Profiles List"
            items={data || []}
            columns={columns}
            pagination={true}
            sorting={sorting}
            itemId="id"
        />
    )
}

export default ProfileList