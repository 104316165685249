export const baseAPIURL = process.env.REACT_APP_API_URL || 'http://localhost:8000'

const s3BucketName = 'education-for-all-india'
const s3Region = 'ap-south-1'

export const getImageURL = ({
                         profileName,
                         phoneNumber,
                         timestamp
                     }) => `https://${s3BucketName}.s3.${s3Region}.amazonaws.com/qr/${profileName.replaceAll(' ', '_')}-${phoneNumber.replaceAll(' ', '_')}-${timestamp}.png`

export const ROUTE_PATHS = {
    DASHBOARD: '/',
    LOGIN: '/login',
    LOGOUT: '/logout',
    ADD_VOLUNTEER: '/add_volunteer',
    ADD_TASK: '/add_task',
    TASK_LIST: '/task_list',
    PROFILES_LIST: '/profiles',
}
