import '@elastic/eui/dist/eui_theme_light.min.css';
import {
    EuiButton,
    EuiCollapsibleNav,
    EuiCollapsibleNavGroup,
    EuiFlexItem,
    EuiHeader,
    EuiHeaderLogo,
    EuiHeaderSection,
    EuiHeaderSectionItem,
    EuiHeaderSectionItemButton,
    EuiIcon,
    EuiPageTemplate,
    EuiProvider,
    EuiText,
} from '@elastic/eui/es';
import AapLogo from '../aap_logo.png'
import {NavLink, useNavigate} from "react-router-dom";
import {useContext, useState} from "react";
import ToastContextProvider from "../context/ToastContext";
import {AuthContext} from "../context/AuthContext";
import {ROUTE_PATHS} from "../constants";

const CollapsibleNavLink = ({path, title}) => {
    return (
        <EuiText size="s" color="subdued" style={{padding: '0 8px 8px'}}>
            <p>
                <NavLink to={path}>{title}</NavLink>
            </p>
        </EuiText>
    )
}

export default function App({
                                children,
                                paths
                            }) {
    const {logout, isLoggedIn, user: {username = ''}} = useContext(AuthContext)
    const navigate = useNavigate();

    const handleAuthButtonClick = () => {
        if (isLoggedIn) {
            console.log('trying to log out')
            logout()
        } else {
            navigate(ROUTE_PATHS.LOGIN)
        }
    }

    const [navIsOpen, setNavIsOpen] = useState(false);
    const collapsibleNav = (
        <EuiCollapsibleNav
            aria-label="Main navigation"
            isOpen={navIsOpen}
            button={
                <EuiHeaderSectionItemButton
                    aria-label="Toggle main navigation"
                    onClick={() => setNavIsOpen(!navIsOpen)}
                >
                    <EuiIcon type={'menu'} size="m" aria-hidden="true"/>
                </EuiHeaderSectionItemButton>
            }
            onClose={() => setNavIsOpen(false)}
        >
            <EuiFlexItem className="eui-yScroll">
                {/* Docs callout */}
                <EuiCollapsibleNavGroup background="none" title="Main Navigation">
                    {
                        paths.filter(({requiresLogin}) => {
                            if(requiresLogin){
                                return isLoggedIn
                            } else {
                                return true
                            }
                        }).map(({path, title}) => <CollapsibleNavLink key={path} path={path} title={title}/>)
                    }
                </EuiCollapsibleNavGroup>
            </EuiFlexItem>
        </EuiCollapsibleNav>
    );

    return (
        <EuiProvider colorMode="light">
            <EuiHeader
                position="static"
            >
                <EuiHeaderSection side={'left'} grow={false}>
                    <EuiHeaderSectionItem border="right">
                        <EuiHeaderLogo iconType={AapLogo}/>
                    </EuiHeaderSectionItem>
                    <EuiHeaderSectionItem border="right">
                        {collapsibleNav}
                    </EuiHeaderSectionItem>
                </EuiHeaderSection>

                <EuiHeaderSection side={'right'} grow={false}>
                    <EuiHeaderSectionItem>
                        <EuiButton fill={!isLoggedIn}>
                            <EuiText onClick={handleAuthButtonClick}>{isLoggedIn ? 'Logout' : 'Login'}</EuiText>
                        </EuiButton>
                    </EuiHeaderSectionItem>
                </EuiHeaderSection>
            </EuiHeader>
            <EuiPageTemplate
                pageHeader={{
                    pageTitle: `Aam Aadmi Party Whatsapp Team`,
                    description: `${username ? `Welcome ${username} ji` : ''}`
                }}
                pageSideBar={undefined}
                fullHeight={true}
                template={'centeredBody'}
            >
                <ToastContextProvider children={children}/>
            </EuiPageTemplate>
        </EuiProvider>
    )
}