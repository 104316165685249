import React, {StrictMode} from 'react'
import {render} from 'react-dom'
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { BrowserRouter } from 'react-router-dom'
import './index.css'
import Routes from './routes'
import reportWebVitals from './reportWebVitals';
import AuthContextProvider from "./context/AuthContext";

Sentry.init({
    dsn: "https://7c147403bc4642c2ae70094a8af16eca@o1129202.ingest.sentry.io/6172951",
    integrations: [new Integrations.BrowserTracing()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});


render(
    <StrictMode>
        <AuthContextProvider>
            <BrowserRouter>
                <Routes />
            </BrowserRouter>
        </AuthContextProvider>
    </StrictMode>,
    document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
