import PropTypes from "prop-types";
import React from "react";
import {EuiConfirmModal} from "@elastic/eui/es";

function DetailsConfirmModal({onCancel, onConfirm, children, cancelText, confirmText}) {
    return (
        <EuiConfirmModal
            title="Confirm Requirements"
            onCancel={onCancel}
            onConfirm={onConfirm}
            cancelButtonText={cancelText}
            confirmButtonText={confirmText}
            defaultFocusedButton="confirm"
        >
            {children}
        </EuiConfirmModal>
    )
}

DetailsConfirmModal.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    cancelText: PropTypes.string.isRequired,
    confirmText: PropTypes.string.isRequired,
}

export default DetailsConfirmModal