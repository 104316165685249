import React, { useState, useCallback, createContext } from 'react'
import {EuiGlobalToastList} from "@elastic/eui/es";

export const ToastContext = createContext({
    addToast: () => {},
    addSuccessToast: ({title, message}) => {},
    addErrorToast: ({title, message}) => {},
})

export default function ToastContextProvider({ children }) {
    const [toastKey, setToastKey] = useState(1)
    const addToastHandler = (toast) => {
        setToastKey(toastKey + 1)
        setToasts([...toasts, {...toast, id: `${toastKey}`}])
    };

    const contextValue = {
        addToast: useCallback((toast) => addToastHandler(toast), []),
        addSuccessToast: useCallback(({title ='Success!', message='All done!'}) => addToastHandler({
            title: title,
            color: 'success',
            text: <p>{message}</p>,
        }), []),
        addErrorToast: useCallback(({title ='Oops, there was an error', message='All done!'}) => addToastHandler({
            title: title,
            color: 'danger',
            iconType: 'help',
            text: <p>{message}</p>,
        }), []),
    }

    const [toasts, setToasts] = useState([]);

    const removeToast = (removedToast) => {
        setToasts(toasts.filter((toast) => toast.id !== removedToast.id));
    };


    return (
        <ToastContext.Provider value={contextValue}>
            <EuiGlobalToastList
                toasts={toasts}
                dismissToast={removeToast}
                toastLifeTimeMs={15000}
            />
            {children}
        </ToastContext.Provider>
    )
}