import React, {Fragment, Suspense} from 'react'
import {Route, Routes, useLocation} from 'react-router-dom'
import App from './pages/_app'
import AddTask from "./pages/add_task";
import Home from "./pages/home";
import TaskList from "./pages/task_list";
import Login from "./pages/login";
import Logout from "./pages/logout";
import AddVolunteer from "./pages/add_volunteer";
import ProfileList from "./pages/profiles";
import RequireAuth from "./components/RequireAuth";
import {ROUTE_PATHS} from "./constants";

export const routes = [
    {
        path: ROUTE_PATHS.DASHBOARD,
        title: 'Dashboard',
        component: Home,
        topLevel: true,
        requiresLogin: true,
    },
    {
        path: ROUTE_PATHS.ADD_VOLUNTEER,
        title: 'Add Volunteer Profile',
        component: AddVolunteer,
        topLevel: true,
        requiresLogin: true,
    },
    {
        path: ROUTE_PATHS.LOGIN,
        title: 'Login',
        component: Login,
        topLevel: false,
        requiresLogin: false,
    },
    {
        path: ROUTE_PATHS.LOGOUT,
        title: 'Logout',
        component: Logout,
        topLevel: false,
        requiresLogin: true,
    },
    {
        path: ROUTE_PATHS.ADD_TASK,
        title: 'Add New Task',
        component: AddTask,
        topLevel: true,
        requiresLogin: true,
    },
    {
        path: ROUTE_PATHS.PROFILES_LIST,
        title: 'Profiles List',
        component: ProfileList,
        topLevel: true,
        requiresLogin: true,
    },
    {
        path: ROUTE_PATHS.TASK_LIST,
        title: 'Task List',
        component: TaskList,
        topLevel: true,
        requiresLogin: true,
    },
]

// const PRESERVED = import.meta.globEager('/src/pages/(_app|404).jsx')
// const ROUTES = import.meta.globEager('/src/pages/**/[a-z[]*.jsx')

// const preserved = Object.keys(PRESERVED).reduce((preserved, file) => {
//     const key = file.replace(/\/src\/pages\/|\.jsx$/g, '')
//     return { ...preserved, [key]: PRESERVED[file].default }
// }, {})
//
// const routes = Object.keys(ROUTES).map((route) => {
//     const path = route
//         .replace(/\/src\/pages|index|\.jsx$/g, '')
//         .replace(/\[\.{3}.+\]/, '*')
//         .replace(/\[(.+)\]/, ':$1')
//
//     return { path, component: ROUTES[route].default }
// })

const preserved = undefined // do this later

export default () => {
    const NotFound = preserved?.['404'] || Fragment
    let location = useLocation();

    return (
        <Suspense fallback={<div>Loading...</div>}>
            <App paths={routes.filter(({topLevel}) => topLevel)}>
                <Routes>
                    {routes.map(({path, requiresLogin, component: Component = Fragment}) => (
                        <Route caseSensitive={false} key={path} path={path} element={<RequireAuth><Component/></RequireAuth>}/>
                    ))}
                    <Route path="*" component={NotFound}/>
                </Routes>
            </App>
        </Suspense>
    )
}