import axios from "axios";
import Cookies from "js-cookie";

const httpClient = axios.create({});

httpClient.interceptors.request.use(function (config) {
    const token = Cookies.get('auth_token');
    config.headers.Authorization =  token ? `Token ${token}` : '';
    return config;
});

export default httpClient
