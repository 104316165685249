import {createContext, useEffect, useState} from 'react'
import Cookies from 'js-cookie'
import httpClient from "../utils/httpClient";
import {baseAPIURL} from "../constants";

const userInitialState = {email: '', username: '', label: ''}

const initialAuthState = {
    isLoggedIn: false, user: userInitialState, logout: () => {
    }, login: () => {
    }, authToken: '', isCheckingLoginState: true,
}

export const AuthContext = createContext(initialAuthState)

const AuthContextProvider = ({children}) => {
    const [user, setUser] = useState(userInitialState)
    const [isCheckingLoginState, setIsCheckingLoginState] = useState(true)
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [authToken, setAuthToken] = useState('')
    const login = (data) => {
        return httpClient.post(`${baseAPIURL}/api-token-auth/`, data).then(({data: {token}}) => {
            setIsLoggedIn(true)
            setAuthToken(token)
            Cookies.set('auth_token', token, {sameSite: 'strict'})
            return {
                isLoggedIn: true
            }
        })
    }

    const logout = () => {
        setUser(userInitialState)
        setIsLoggedIn(false)
        setAuthToken('')
        Cookies.set('auth_token', '', {expires: new Date() - 100})
    }

    useEffect(() => {
        setIsCheckingLoginState(true)
        console.log('checking if cookie is there')
        // TODO: should make a request to the back-end to see if this token is valid
        if (Cookies.get('auth_token')) {
            console.log('cookie found attempting to test validity');
            (async () => {
                try {
                    const response = await httpClient.get(`${baseAPIURL}/me/`)
                    setIsLoggedIn(true)
                    setAuthToken(Cookies.get('auth_token'))
                    setUser(response.data)
                } catch ({response: {data}}) {
                    logout()
                    console.error({errorData: data})
                } finally {
                    setIsCheckingLoginState(false)
                }
            })();
        } else {
            setIsCheckingLoginState(false)
        }
    }, [])

    const contextValue = {
        login, logout, isLoggedIn: authToken && isLoggedIn, authToken, isCheckingLoginState, user,
    }

    return (<AuthContext.Provider value={contextValue}>
        {children}
    </AuthContext.Provider>)
}

export default AuthContextProvider