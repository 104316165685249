import {EuiButton, EuiFlexGroup, EuiFlexItem, EuiIcon, EuiPanel, EuiStat} from "@elastic/eui/es";
import {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {ROUTE_PATHS} from "../constants";

function Home() {
    const [isLoading, setLoading] = useState(true)
    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        // fake async
        setTimeout(() => {
            setLoading(false)
        }, 1500)
    }, [])

    return (
        <>
            <EuiFlexGroup>
                <EuiFlexItem>
                    <EuiPanel>
                        <EuiStat
                            title="8,888"
                            description="Total Messages Sent"
                            textAlign="right"
                            isLoading={isLoading}
                        >
                            <EuiIcon type="empty"/>
                        </EuiStat>
                    </EuiPanel>
                </EuiFlexItem>
                <EuiFlexItem>
                    <EuiPanel>
                        <EuiStat
                            title="2,000"
                            description="Queued Messages"
                            titleColor="accent"
                            textAlign="right"
                            isLoading={isLoading}
                        >
                            <EuiIcon type="clock" color="accent"/>
                        </EuiStat>
                    </EuiPanel>
                </EuiFlexItem>
                <EuiFlexItem>
                    <EuiPanel>
                        <EuiStat
                            title="6,800"
                            description="Messages Sent Successfully"
                            titleColor="success"
                            textAlign="right"
                            isLoading={isLoading}
                        >
                            <EuiIcon type="check" color="success"/>
                        </EuiStat>
                    </EuiPanel>
                </EuiFlexItem>
            </EuiFlexGroup>
            <EuiFlexGroup>
                <EuiFlexItem>
                    <EuiButton
                        iconType="pencil"
                        onClick={() => {
                            navigate(ROUTE_PATHS.ADD_TASK)
                        }}
                        fill
                    >
                        Schedule new message
                    </EuiButton>
                </EuiFlexItem>
            </EuiFlexGroup>
            <EuiFlexGroup>
                <EuiFlexItem>
                    <EuiButton
                        iconType={'plusInCircle'}
                        onClick={() => {
                            navigate(ROUTE_PATHS.ADD_VOLUNTEER)
                        }}
                    >Register New Volunteer</EuiButton>
                </EuiFlexItem>
            </EuiFlexGroup>
        </>
    )
}

export default Home